body {
  background-color: #f2f2ed;
}

@media only screen and (max-width: 1280px) {
  .top-part.burger-opened .burger-menu {
    cursor: pointer;
  }
  .top-part.burger-opened .navbar-collapse {
    max-height: 240px;
  }
  .top-part.burger-opened .navbar {
    max-height: 240px;
  }
  .top-part.burger-opened .burger-menu .not-focused {
    transform: translateY(1.5rem);
  }
  .top-part.burger-opened .burger-menu .flex.focused {
    width: 3rem;
    transform: translateX(0);
  }
  .top-part.burger-opened .burger-menu .focused:not(.flex) {
    transform: rotate(45deg);
  }
  .top-part.burger-opened .burger-menu .focused:not(.flex).back {
    transform: rotate(-45deg);
  }
  .burger-menu {
    position: absolute;
    right: 4px;
    top: 0;
    z-index: 2;
  }
  .top-part {
    position: fixed;
    background-color: white;
  }
  .navbar {
    max-height: 56px;
    min-height: 56px;
    height: 100%;
    transition: max-height 0.3s ease-in-out;
  }
  .navbar > div {
    text-align: left;
    padding-left: 8px;
  }
  .navbar .navbar-nav {
    padding-left: 0;
    text-align: left;
    padding-top: 56px;
  }
  .navbar img {
    top: 4px;
    left: 8px;
  }
  .navbar-collapse {
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .product-card img {
    min-height: calc(100vw - 6rem);
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .product-card img {
    min-height: calc(426.6666666667px - 6rem);
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 1280px) {
  .burger-menu {
    display: none;
  }
}

